import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../../../recoil/atoms/Clients';
import { CollapsedForm, CollapsedFormType } from '../../../shared/collapsed-form/CollapsedForm';
import ActionBaseProps from '../ActionBaseProps';
import { ChildFormRenderType } from '../child-form/ChildFormRenderType';
import { FormConfig } from '../../../../models/Form';
import TemplateFormService from '../../../../services/TemplateFormService';
import { FormRendererType, useFormRendererInfo } from '../../../../contexts/FormRendererContext';
import { useFormAction } from '../../../../contexts/FormActionContext';
import { FormType } from '../../../../models/FormTypes';
import ClientTemplateFormService from '../../../../services/ClientTemplateFormService';
import ActionTitleDescription from '../ActionTitleDescription';
import ClientFormService from '../../../../services/ClientFormService';
import { ToastType, useToasts } from '../../../../contexts/ToastContext';
import { useTranslation } from 'react-i18next';

export type PreSelectedFormActionData = {
  renderType: ChildFormRenderType;
  newFormTitle: string;
  templateFormId: string;
};

export type PreSelectedFromActionProps = ActionBaseProps<string, PreSelectedFormActionData>;

const PreSelectedFormAction: FC<PreSelectedFromActionProps> = (props) => {
  const { response, id, data, required } = props;
  const { templateFormId, newFormTitle, renderType } = data;
  const { isDisplayedInline, containingModuleId, containingModuleSectionId, clientFormId, createForm, type } = useFormRendererInfo();
  const { onAnswer, onValid, currentSection, currentAction, readOnly } = useFormAction(props);
  const busyCreatingForm = useRef(false);

  const [template, setTemplate] = useState<FormConfig | null>(null);

  const client = useRecoilValue(currentClientAtom);
  const clientId = client?.id || '';

  const { t } = useTranslation(['activity-type', 'form-builder']);
  const toasts = useRef(useToasts());

  const templateService = useMemo(() => {
    if (client) {
      return new ClientTemplateFormService(clientId);
    }
    return TemplateFormService;
  }, [client, clientId]);

  useEffect(() => {
    if (!templateFormId) {
      return;
    }
    templateService.getFormTemplate(templateFormId).then((res) => {
      setTemplate(res.data);
    });
  }, [templateFormId, templateService, clientId]);

  // Create form when this becomes visible
  useEffect(() => {
    if (!template || !clientFormId) {
      return;
    }

    if (clientId && !response && !busyCreatingForm.current) {
      busyCreatingForm.current = true;
      createForm({
        clientId: clientId,
        subtitle: newFormTitle,
        templateFormId: templateFormId,
        clientModuleId: template.type === FormType.Asset ? undefined : containingModuleId,
        clientModuleSectionId: template.type === FormType.Asset ? undefined : containingModuleSectionId,
        parentId: clientFormId,
        parentSectionId: currentSection.id,
        parentActionId: currentAction.id,
      }).then((res) => {
        onAnswer(res?.id || null).catch((err) => {
          toasts.current.addToast({
            title: t('activity-type:child-form-list.error-creating'),
            description: err?.data?.meta?.message,
            type: ToastType.ERROR,
          });
          onAnswer(null);
          ClientFormService.deleteForm(res?.id || '');
        });
        // Don't create another form within 1s - fixes timing issues
        setTimeout(() => {
          busyCreatingForm.current = false;
        }, 1000);
      });
    }
  }, [
    clientFormId,
    clientId,
    containingModuleId,
    containingModuleSectionId,
    createForm,
    currentAction.id,
    currentSection.id,
    id,
    newFormTitle,
    onAnswer,
    response,
    t,
    template,
    templateFormId,
  ]);

  const subFormOnValidate = useCallback(
    (value: boolean) => {
      if (!value) {
        onValid(value);
      } else {
        onValid(undefined);
      }
    },
    [onValid],
  );

  return (
    <div data-cy="pre-selected-form">
      <div>
        <div>
          <ActionTitleDescription required={required} {...data} />
        </div>
        {response && (
          <CollapsedForm
            formType={CollapsedFormType.Subform}
            data-cy={`pre-selected-form-${response}`}
            formId={response}
            onValidate={subFormOnValidate}
            showInline={(!isDisplayedInline && renderType === ChildFormRenderType.Inline) || type === FormRendererType.Public}
            canEdit={!readOnly}
          />
        )}
        {!client && <div className="my-4 italic">{t('form-builder:action-properties.preselectedform.preview-not-allowed')}</div>}
      </div>
    </div>
  );
};

export default PreSelectedFormAction;
